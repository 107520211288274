import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
    background-color: #F0F0F0;
    padding: 6px 0;
    color: #808080;
    font-size: 10px;
  `;
  
const Footer = () => {

  return (
    <StyledFooter className="text-center px-2">
      &copy; 2010 - 2021 JR-ZQSI-NICHE. All Rights Reserved.
      <br/>
      All content and tools should not be used alone to guide patient care, nor should they substitute for clinical judgment.
    </StyledFooter>
  )
}

export default Footer
