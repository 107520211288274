import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from "styled-components";

const TextLabel = styled.span`
  font-size: 14px;
  display: block;
`;

const GCSResult = ({answers}) => {
  const scores = answers.map(answer => answer.value);
  const renderTotalScore = () => {
    return (
      <h2>
        {scores.reduce((a, b) => a + b)} points
        <TextLabel className="mt-2">Glasgow Coma Score</TextLabel>
      </h2>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={6}>
          {renderTotalScore()}
        </Col>
        <Col md={6}>
          <h2>E({Object.values(answers)[0].value}) V({Object.values(answers)[1].value}) M({Object.values(answers)[2].value})
            <TextLabel className="mt-2">Glasgow Coma Scale</TextLabel> 
          </h2>
        </Col>
      </Row>
    </Container>
  )
}

export default GCSResult
