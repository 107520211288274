import React from "react";
import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'

const Result = (props) => {
  const {
    questionData
  } = props;
  const { selectedAnswers } = useSelector((state) => state.quiz);

  return (
    <div>
      <Table responsive="sm" bordered size="lg" className="mt-3">
        <tbody>
          {selectedAnswers.map(({id, option}, index) => {
            console.log(questionData[id].options)
            return (
              <tr key={index}>
                <td align="left">{questionData[id].question}</td>
                <td align="left">{questionData[id].options[option].value}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default Result
