import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { questionData } from "./data";
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Quiz from "./components/Quiz";

import { nextQuestion, prevQuestion, saveAnswer, startLoading, stopLoading } from "./slices/quiz";
import GCSCalculatorModal from "./components/GCSCalculatorModal";
import ICHCalculatorModal from "./components/ICHCalculatorModal";
import Statement from "./components/Statement";
import ContributorsModal from "./components/ContributorsModal";

function App() {
  const dispatch = useDispatch();
  const { loading, currentStep, selectedAnswers } = useSelector((state) => state.quiz);

  const [showContributors, setShowContributors] = useState(false);
  const [showGCSModal, setShowGCSModal] = useState(false);
  const [showICHModal, setShowICHModal] = useState(false);
  const [hideStatement, setHideStatement] = useState(false);
  const [start, setStart] = useState(false);
  const [isMedicalManagement, setMedicalManagement] = useState(false);
  const [isSurgicalManagement, setSurgicalManagement] = useState(false);
  const [showResult, setShowResult] = useState(false);

  //Modal state manipulation
  const handleCloseGCSModal = () => setShowGCSModal(false);
  const handleCloseICHModal = () => setShowICHModal(false);
  const handleCloseContributors = () => setShowContributors(false);

  const handleNextStep = (qIndex, num, event) => {
    console.log(event.target.value);

    //save answer before next question
    const selectedData = questionData.find((e) => e.id === currentStep);
    dispatch(saveAnswer({id: qIndex, option: event.target.value}));
    
    if(num === 101) {
      dispatch(startLoading())
      setTimeout(() => {
        setSurgicalManagement(false);
        setMedicalManagement(true);
        setShowResult(true);
        dispatch(stopLoading());
      }, 1000)
    } else if(num === 102) {
      dispatch(startLoading())
      setTimeout(() => {
        setSurgicalManagement(true);
        setMedicalManagement(false);
        setShowResult(true);
        dispatch(stopLoading())
      }, 1000)
    } else {
      dispatch(nextQuestion(num));
    }
  }

  const handleBack = () => {
    const prevStep = selectedAnswers.length;
    console.log('prevStep', prevStep);
    const goBackToNumber = selectedAnswers[prevStep - 1].id;
    console.log('goBackToNumber', goBackToNumber + 1);
    dispatch(prevQuestion(goBackToNumber + 1));
    setSurgicalManagement(false);
    setMedicalManagement(false);
    setShowResult(false);
  }

  const handleStart = () => {
    dispatch(startLoading())
    setTimeout(() => {
      setStart(true)
      dispatch(stopLoading());
    }, 1000)
  }

  const handleProceed = () => {
    setHideStatement(true);
  }

  const handleBackToHome = () => {
    window.location.reload();
  }

  useEffect(() => {
    console.log('showContributors', showContributors);
  }, [showContributors])

  return (
    <div className="App">
      <Header showGCSModal={setShowGCSModal} showICHModal={setShowICHModal} showContributors={setShowContributors}/>

      {hideStatement ? (
        <Quiz
          isMedicalManagement={isMedicalManagement}
          isSurgicalManagement={isSurgicalManagement}
          loading={loading}
          currentStep={currentStep}
          nextStep={handleNextStep}
          prevStep={handleBack}
          backToHome={handleBackToHome}
          questionData={questionData}
          showResult={showResult}
          startQuiz={handleStart}
          start={start}
          answers={selectedAnswers}
        />
      ): (
        <Statement proceed={handleProceed}/>
      )}

      <GCSCalculatorModal closeGCS={handleCloseGCSModal} showGCSModal={showGCSModal}/>
      <ICHCalculatorModal closeICH={handleCloseICHModal} showICHModal={showICHModal}/>
      <ContributorsModal closeContributors={handleCloseContributors} showContributors={showContributors}/>

      <Footer/>
    </div>
  );
}

export default App;
