import React from 'react'
import OptionItem from './OptionItem'

const Options = ({questionIndex, data, nextStep}) => {
  return (
    <div>
      {
        data.options.map((option, index) => {
          return (
            <OptionItem
              key={index}
              index={index}
              text={option.value}
              nextStep={(event) => nextStep(questionIndex, option.next_question, event)}
            />
          )
        })
      }
    </div>
  )
}

export default Options
