import React from 'react'

const Question = ({data, StyledTitle, PreText}) => {

  return (
    <div className={(data.hide_question ? 'd-none' : '' )}>

      {data.pre_text && (
        <PreText className="mb-3 py-3 px-2">{data.pre_text}</PreText>
      )}
      <StyledTitle className="mb-4">{ data.question }</StyledTitle>
    </div>
  )
}

export default Question
