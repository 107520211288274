import React from 'react'
import { ListGroup } from 'react-bootstrap'
import styled from "styled-components"

const List = styled(ListGroup.Item)`
  cursor: pointer;

  &:hover,&:active,&:focus {
    background-color: #15A110;
    color: #fff;
  }
`;

const GCSOptions = ({step, options, selectAnswer}) => {
  return (
    <ListGroup className="question_options mt-3">
      {options.map((option, index) => {
        return (
          <List key={index} onClick={(e) => selectAnswer(step, option.value)}>{ option.label }</List>
        )
      })}
    </ListGroup>
  )
}

export default GCSOptions
