import React from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';

const Statement = ({proceed}) => {
  return (
    <div className="card-wrapper">
      <Container>
        <Row>
          <Col
            className="d-flex align-items-center flex-column justify-content-center text-center"
            xs={12}
            md={{ span: 6, offset: 3 }}
          >
            <Card>
              <Card.Body>
                <Card.Text className="break-word">
                  The following algorithm is adapted and modified from “Indication, Timing, and Surgical Treatment of Spontaneous Intracerebral Hemorrhage: Systematic Review and Proposal of a Management Algorithm” by Luzzi, et. al. published in World Neurosurgery in 2019. (<a href="https://doi.org/10.1016/j.wneu.2019.01.016">https://doi.org/10.1016/j.wneu.2019.01.016</a>)
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-center">
                <Button onClick={proceed}>Proceed</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Statement
