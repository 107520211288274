import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { gcsData } from "../data";
import GCSOptions from "./GCSOptions";
import GCSResult from "./GCSResult";

const GCSCalculatorModal = ({ showGCSModal, closeGCS }) => {
  const [answers, setAnswers] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [quizComplete, setQuizComplete] = useState(false);
  const currentQuestion = gcsData[currentStep];
  const questionLength = gcsData.length;

  const handleSelectAnswer = (index, value) => {
    const answer = {
      id: index,
      value: value,
    };
    setAnswers([...answers, answer]);
    if (questionLength >= currentStep) {
      setCurrentStep((prevState) => {
        const newStep = prevState + 1;
        return newStep;
      });
    } else {
      console.log("completing..");
      setQuizComplete(true);
    }
  };

  const closeModal = () => {
    setAnswers([]);
    setQuizComplete(false);
    setCurrentStep(0);
    closeGCS();
  };

  useEffect(() => {
    console.log("answers", answers);
    console.log("setCurrentStep", currentStep);
  }, [answers]);

  return (
    <Modal show={showGCSModal} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Glasgow Coma Scale/Score (GCS)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!quizComplete && currentQuestion !== undefined ? (
          <div>
            <div>
              <Form.Label as="p" className="mb-0">
                {currentQuestion.question}
              </Form.Label>
              {currentQuestion.help_text && (
                <Form.Text muted>{currentQuestion.help_text}</Form.Text>
              )}
            </div>
            <GCSOptions
              step={currentStep}
              options={currentQuestion.options}
              selectAnswer={handleSelectAnswer}
            />
          </div>
        ) : (
          <GCSResult answers={answers} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GCSCalculatorModal;
