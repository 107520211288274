import React from 'react';
import styled from 'styled-components';
import { Navbar, Container, Nav } from 'react-bootstrap';
import jrrmmcOfficial from '../../../src/jrrmmc-official.png';

const NavbarText = styled.div`
    font-size: 14px;
    text-align: left;

    @media(max-width: 567px) {
      h4 {
        font-size: 16px;
      }
    }
  `;

const Header = ({showGCSModal, showICHModal, showContributors}) => {
  
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img src={jrrmmcOfficial} alt="JR-Niche" className="img-fluid"/>
        </Navbar.Brand>
        <NavbarText className="ml-3 brand-text">
          <h4 className="">JR-ZQSI-NICHE</h4>
          <p className="d-none d-md-block mb-0">JRRMMC Neurology and Neurosurgery Intracerebral Hemorrhage Evaluation<br/>
          Zeenat Qureshi Stroke Institute</p>
        </NavbarText>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link onClick={() => showGCSModal(true)} eventKey={2} href="#GCS">
              GCS Calculator
            </Nav.Link>
            <Nav.Link onClick={() => showICHModal(true)} eventKey={2} href="#ICH">
              ICH Volume Calculator
            </Nav.Link>
            <Nav.Link onClick={() => showContributors(true)} eventKey={2} href="#Contributors">
              Contributors
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
