import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalBody = styled(Modal.Body)`
    font-size: 14px;
    text-align: left;
    > div {
      padding: 0.3rem 0;
    }

    p {
      font-weight: bold;
      margin-bottom: 0;
    }
  `;

const ContributorsModal = ({showContributors, closeContributors}) => {
  const closeModal = () => {
    closeContributors();
  };

  return (
    <Modal show={showContributors} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Contributors to the App</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <p>Conceptualization and Design:</p>
        <div>Jose C. Navarro, MD, MSc, FPNA</div>
        <div>Ma. Priscille T. Ogena, MD</div>
        <p className="mt-3">Contributors:</p>
        <div>Herminigildo H. Gan, MD, FPCP, FPNA</div>
        <div>Romulo U. Esagunde, MD, FPNA</div>
        <div>Joseph Erroll V. Navarro, MD, FAFNI, FPCS</div>
        <div>Cyrus G. Escabillas, MD, FPNA</div>
        <div>Maria Anna F. de Guzman, MD, FPNA</div>
        <div>Steven G. Villaraza, MD, FPNA</div>
        <div>Ma. Jeasa G. Torrefranca, MD</div>
        <div>Josephine Cecilia V. Roque, MD</div>
      </ModalBody>
    </Modal>
  )
}

export default ContributorsModal
