import { Button, Container, Card, Row, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Question from "./Question";
import Options from "./Options";
import brainBg from "../../src/brain-pattern.png";
import pattern from "../../src/pattern.png";
import brainStart from "../../src/brain.png";
import Result from "./Result";
import Start from "./Start";

const QuizContainer = styled.div`
  background-color: #15a110;
  background-image: url(${pattern});
  background-repeat: no-repeat;
  background-size: cover;
`;

const CardBox = styled(Card)`
  background-image: url(${brainBg});
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 24px 32px #00000026;
  border: 1px solid #009b2440;
  border-radius: 16px;
  min-height: 300px;

  @media (min-width: 1024px) {
    min-height: 455px;
  }

  &.background-opacity:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 16px;
  }

  .body-elevate {
    z-index: 1;
  }
`;

const CardBoxStart = styled(Card)`
  min-height: 300px;
  box-shadow: 0px 24px 32px #00000026;
  border: 1px solid #009b2440;
  border-radius: 16px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    background-image: url(${brainStart});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    transform: translate(-50%, 60%);
    bottom: 0%;
    left: 50%;
  }
`;

const StyledSpinner = styled(Spinner)`
  text-align: center;
  margin: 0 auto;
`;

const StyledTitle = styled.h1`
  font-size: 26px;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const PreText = styled.div`
  max-width: 450px;
  margin: 0 auto;
  font-size: 14px;
`;

const BackButton = styled(Button)``;

const CopyBtn = styled(Button)`
  max-width: 220px;
  color: #15a110;
  flex: 0 70% !important;
`;

const StatementText = styled.div`
  font-size: 14px;
`;

const CardFooter = styled(Card.Footer)`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  button {
    flex: 0 50%;
  }
`;

const ResultContainer = styled.div`
  overflow-y: auto;
  max-height: 320px;
`;

const Quiz = (props) => {
  const {
    isMedicalManagement,
    isSurgicalManagement,
    questionData,
    showResult,
    currentStep,
    backToHome,
    prevStep,
    nextStep,
    loading,
    startQuiz,
    answers,
    start,
  } = props;

  const renderStatement = () => {
    let statement = "For a patient with spontaneous intracerebral hemorrhage,";
    answers.map(({ id, option }) => {
      statement += questionData[id].statement_value
        ? " " +
          questionData[id].statement.replace(
            "$variable",
            questionData[id].remove_text ? questionData[id].options[option].value.replace(questionData[id].remove_text, '').toLowerCase() :  questionData[id].options[option].value.toLowerCase()
          )
        : "";
      if (questionData[id].options[option].statement_option) {
        statement = statement.replace(
          "$option_variable",
          questionData[id].options[option].statement_option
        );
      }
    });
    statement += isMedicalManagement
      ? " medical management"
      : " surgical management";
    statement += " is recommended.";
    return statement;
  };

  const copyToClipboard = () => {
    toast("Results copied", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <QuizContainer className="card-wrapper">
      <Container>
        <Row className="align">
          <Col
            className="d-flex align-items-center flex-column justify-content-center text-center"
            xs={12}
            md={{ span: 6, offset: 3 }}
          >
            {start ? (
              <>
                {!showResult ? (
                  <>
                    <CardBox className="w-100 mb-3">
                      <Card.Body className="d-flex flex-column justify-content-center">
                        {!loading ? (
                          <>
                            {!isMedicalManagement && !isSurgicalManagement ? (
                              <>
                                <Question
                                  PreText={PreText}
                                  StyledTitle={StyledTitle}
                                  data={questionData[currentStep - 1]}
                                />
                                <Options
                                  questionIndex={currentStep - 1}
                                  data={questionData[currentStep - 1]}
                                  nextStep={nextStep}
                                />
                                {currentStep - 1 > 0 && (
                                  <BackButton
                                    variant="default"
                                    onClick={prevStep}
                                  >{`< Back`}</BackButton>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <StyledSpinner animation="border" />
                        )}
                      </Card.Body>
                    </CardBox>

                    {questionData[currentStep - 1].info_text && (
                      <Card className="w-100">
                        <Card.Body>
                          <Card.Text>
                            {questionData[currentStep - 1].info_text}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                ) : (
                  <CardBox className="w-100 mb-3 background-opacity">
                    <Card.Body className="d-flex flex-column justify-content-center body-elevate">
                      <ResultContainer>
                        <h4>
                          {isMedicalManagement
                            ? "Medical Management"
                            : "Surgical Management"}
                        </h4>
                        <StatementText className="text-muted">
                          {renderStatement()}
                        </StatementText>

                        <CopyToClipboard
                          onCopy={copyToClipboard}
                          text={renderStatement()}
                        >
                          <CopyBtn className="mt-3">Copy Result</CopyBtn>
                        </CopyToClipboard>

                        <Result questionData={questionData} />
                      </ResultContainer>
                    </Card.Body>
                    <CardFooter>
                      <BackButton
                        variant="default"
                        onClick={prevStep}
                      >{`< Back`}</BackButton>

                      <BackButton
                        variant="default"
                        onClick={backToHome}
                      >{`Start Over >`}</BackButton>
                    </CardFooter>
                  </CardBox>
                )}
              </>
            ) : (
              <Start
                startQuiz={startQuiz}
                CardBoxStart={CardBoxStart}
                StyledTitle={StyledTitle}
                loading={loading}
                StyledSpinner={StyledSpinner}
              />
            )}
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </QuizContainer>
  );
};

export default Quiz;
