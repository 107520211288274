import React from 'react'
import { Card, Button } from 'react-bootstrap'

const Start = ({CardBoxStart, startQuiz, StyledTitle, loading, StyledSpinner}) => {
  return (
    <CardBoxStart className="w-100 mb-3 card-box">
      <Card.Body className="d-flex flex-column justify-content-center">
        
        {!loading ? (
          <>
            <StyledTitle className="mb-3">Neurology and Neurosurgery Intracerebral Hemorrhage Evaluation</StyledTitle>
            <Button onClick={startQuiz} className="btn btn-success btn-start" style={{zIndex: "1"}}>{`Begin Test >`}</Button>
          </>
        ): (
          <StyledSpinner animation="border" />
        )}
      </Card.Body>
    </CardBoxStart>
  )
}

export default Start
