import React from "react";
import { Button } from "react-bootstrap";

const OptionItem = ({ text, nextStep, index }) => {
  return (
    <>
      <Button className="mb-3 mx-2 option-item__btn" variant='primary' value={index} onClick={nextStep}>
        {text}
      </Button>
    </>
  );
};

export default OptionItem;
