import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedAnswers: [],
  loading: false,
  currentStep: 1
};

const slice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true
    },

    stopLoading(state) {
      state.loading = false
    },

    nextQuestionSuccess(state, action) {
      state.loading = false;
      state.currentStep = action.payload;
    },

    prevQuestionSuccess(state, action) {
      state.loading = false;
      state.currentStep = action.payload;
      state.selectedAnswers = state.selectedAnswers.slice(0,-1);
    },

    saveAnswerSuccess(state, action) {
      state.selectedAnswers = [...state.selectedAnswers, action.payload]
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    }
  },
});

export const { startLoading, stopLoading } = slice.actions;

export default slice.reducer;


export function nextQuestion(numStep) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    setTimeout(() => {
      dispatch(slice.actions.nextQuestionSuccess(numStep));
    }, 1000)
  };
}

export function prevQuestion(numStep) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    setTimeout(() => {
      dispatch(slice.actions.prevQuestionSuccess(numStep));
    }, 1000)
  };
}

export function saveAnswer(answer) {
  return (dispatch) => {
    dispatch(slice.actions.saveAnswerSuccess(answer));
  }
}