import React, { useState, useRef } from "react";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import { ichData } from "../data";
import ICHResult from "./ICHResult";

const ICHCalculatorModal = ({ showICHModal, closeICH }) => {
  const inputAnswer = useRef("");
  const [inputList, setInputList] = useState([]);
  const [thickness, setThickness] = useState(4);
  const [currentStep, setCurrentStep] = useState(0);
  const [quizComplete, setQuizComplete] = useState(false);
  const currentQuestion = ichData[currentStep];
  const questionLength = ichData.length;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index] = parseFloat(value);
    setInputList(list);
  };

  const handleNextQuestion = () => {
    setCurrentStep((prevState) => {
      const newStep = prevState + 1;
      return newStep;
    });
    if(currentStep <= 2) {
      inputAnswer.current.value="";
      inputAnswer.current.focus();
    }
  }

  const closeModal = () => {
    closeICH();
    setInputList([]);
    setCurrentStep(0);
    setQuizComplete(false);
    setThickness(4);
  };

  const handleChangeThickness = (e) => {
    const { value } = e.target;
    console.log(value);
    setThickness(value);
  };

  return (
    <Modal show={showICHModal} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>ICH Volume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!quizComplete && currentQuestion !== undefined ? (
          <div>
            <Form.Group className="mb-3">
              <Form.Label as="p" className="mb-0">
                {currentQuestion.question}
              </Form.Label>

              {currentStep >= 3 ? (
                <InputGroup>
                  <Form.Control as="select" onChange={handleChangeThickness}>
                    <option value="4">5</option>
                    <option value="2">10</option>
                  </Form.Control>
                  {currentQuestion.measurement && (
                    <InputGroup.Text>{currentQuestion.measurement}</InputGroup.Text>
                  )}
                </InputGroup>
              ): (
                <InputGroup>
                  <Form.Control
                    name="answer"
                    ref={inputAnswer}
                    onChange={(e) => handleInputChange(e, currentStep)}
                    type="text"
                    autoComplete="off"
                  />
                  {currentQuestion.measurement && (
                    <InputGroup.Text>{currentQuestion.measurement}</InputGroup.Text>
                  )}
                </InputGroup>
              )}
            </Form.Group>

            <Button variant="primary" type="button" onClick={handleNextQuestion}>
              Next Question
            </Button>
          </div>
        ) : (
          <ICHResult thickness={thickness} inputList={inputList} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ICHCalculatorModal;
