export const questionData = [
  {
    id: 1,
    question: 'Intracerebral Hemorrhage',
    hide_question: false,
    statement: "asd",
    statement_value: false,
    remove_text: null,
    options: [
      {
        value: 'Coagulopathy-induced',
        next_question: 19
      },
      {
        value: 'Spontaneous or Primary',
        next_question: 2
      }
    ]
  },
  {
    id: 2,
    question: 'Location of hematoma',
    hide_question: false,
    statement: "$variable in location,",
    statement_value: true,
    remove_text: 'Hemorrhage',
    options: [
      {
        value: 'Supratentorial',
        next_question: 3
      },
      {
        value: 'Infratentorial',
        next_question: 6
      },
      {
        value: 'Primary Intraventricular Hemorrhage',
        next_question: 17
      }
    ]
  },
  {
    id: 3,
    question: 'Glasgow Coma Scale',
    hide_question: false,
    statement: "with GCS score $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: '<5',
        next_question: 101
      },
      {
        value: '5-8',
        next_question: 4
      },
      {
        value: '9-15',
        next_question: 5
      }
    ]
  },
  {
    id: 4,
    question: 'Baseline volume of hematoma',
    hide_question: false,
    statement: "with baseline volume of hematoma $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: '>60 mL',
        next_question: 102
      },
      {
        value: '30-60 mL',
        next_question: 9
      },
      {
        value: '<30 mL',
        next_question: 18
      }
    ]
  },
  {
    id: 5,
    question: 'Baseline volume of hematoma',
    hide_question: false,
    statement: "with baseline volume of hematoma $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: '≥ 30 mL',
        next_question: 102
      },
      {
        value: '<30 mL',
        next_question: 16
      }
    ]
  },
  {
    id: 6,
    question: 'Infratentorial',
    hide_question: true,
    statement: "$option_variable",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Brainstem',
        statement_option: "at the brainstem region,",
        next_question: 101
      },
      {
        value: 'Cerebellum',
        statement_option: " at the region of the cerebellum,",
        next_question: 7
      }
    ]
  },
  {
    id: 7,
    question: 'Widest diameter',
    hide_question: false,
    statement: "with widest diameter $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: '≥ 3 cm',
        next_question: 102
      },
      {
        value: '< 3 cm',
        next_question: 8
      }
    ]
  },
  {
    id: 8,
    question: 'Brainstem compression +/- hydrocephalus',
    hide_question: false,
    statement: "$option_variable brainstem compression and/or hydrocephalus,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 102
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 101
      }
    ]
  },
  {
    id: 9,
    question: 'Signs of increased intracranial pressure*',
    hide_question: false,
    info_text: "*Including intracranial hypertension (pulsatility index >1.26 on transcranial doppler) and/or lateral rectus palsy",
    statement: "$option_variable sign/s of increased intracranial pressure,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 10
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 14
      }
    ]
  },
  {
    id: 10,
    question: 'Location of hematoma',
    hide_question: false,
    statement: "and with hematoma located $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Superficial',
        next_question: 102
      },
      {
        value: 'Deep',
        next_question: 12
      }
    ]
  },
  {
    id: 11,
    question: 'Location of hematoma',
    hide_question: false,
    statement: "and with hematoma located $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Superficial',
        next_question: 102
      },
      {
        value: 'Deep',
        next_question: 12
      }
    ]
  },
  {
    id: 12,
    question: 'Basal Ganglia or Thalamus',
    hide_question: true,
    statement: "in the $variable,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Basal Ganglia',
        next_question: 102
      },
      {
        value: 'Thalamus',
        next_question: 13
      }
    ]
  },
  {
    id: 13,
    question: 'Superficial extension',
    hide_question: false,
    statement: "$option_variable superficial extension,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 102
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 14
      }
    ]
  },
  {
    id: 14,
    question: 'Intraventricular hemorrhage/extension',
    hide_question: false,
    statement: "$option_variable intraventricular extension,",
    statement_value: true,
    remove_text: null,
    options: [ 
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 15
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 101
      }
    ]
  },
  {
    id: 15,
    question: 'Hydrocephalus',
    hide_question: false,
    statement: "$option_variable hydrocephalus,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 102
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 101
      }
    ]
  },
  {
    id: 16,
    question: 'Signs of increased ICP on monitoring*',
    hide_question: false,
    info_text: "*Including intracranial hypertension (pulsatility index >1.26 on transcranial doppler), lateral rectus palsy, absent or compressed cisterns, midline shift >0.5 cm",
    statement: "$option_variable sign/s of increased intracranial pressure,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 102
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 101
      }
    ]
  },
  {
    id: 17,
    question: 'Hydrocephalus',
    hide_question: false,
    statement: "$option_variable hydrocephalus,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        statement_option: "with",
        next_question: 102
      },
      {
        value: 'No',
        statement_option: "without",
        next_question: 101
      }
    ]
  },
  {
    id: 18,
    question: 'Intracranial pressure on monitoring',
    hide_question: false,
    statement: "with $variable on monitoring,",
    statement_value: true,
    remove_text: null,
    options: [
      {
        value: 'Signs of Increased Intracranial Pressure',
        next_question: 10
      },
      {
        value: 'Stability or Improvement',
        next_question: 101
      }
    ]
  },
  {
    id: 19,
    question: 'Coagulopathy already corrected',
    pre_text: 'For a patient with coagulopathy-induced intracerebral hemorrhage, correction of INR or coagulopathy is suggested first.',
    hide_question: false,
    remove_text: null,
    options: [
      {
        value: 'Yes',
        next_question: 2
      },
      {
        value: 'No',
        next_question: 19
      }
    ]
  },
];

export const gcsData = [
  {
    question: 'Best eye response',
    help_text: 'If local injury, edema, or otherwise unable to be assessed, mark "Not testable (NT)"',
    scorable: true,
    options: [
      {
        label: 'Spontaneously (+4)',
        value: 4
      },
      {
        label: 'To verbal command (+3)',
        value: 3
      },
      {
        label: 'To pain (+2)',
        value: 2
      },
      {
        label: 'No eye opening (+1)',
        value: 1
      },
      {
        label: 'Not testable (NT)',
        value: 0
      }
    ]
  },
  {
    question: 'Best verbal response',
    help_text: 'If intubated or otherwise unable to be assessed, mark "Not testable (NT)"',
    scorable: true,
    options: [
      {
        label: 'Oriented (+5)',
        value: 5
      },
      {
        label: 'Confused (+4)',
        value: 4
      },
      {
        label: 'Inappropriate words (+3)',
        value: 3
      },
      {
        label: 'Incomprehensible sounds (+2)',
        value: 2
      },
      {
        label: 'No verbal response (+1)',
        value: 1
      },
      {
        label: 'Not testable/intubated (NT)',
        value: 0
      }
    ]
  },
  {
    question: 'Best motor response',
    help_text: 'If on sedation/paralysis or unable to be assessed, mark "Not testable (NT)"',
    scorable: true,
    options: [
      {
        label: 'Obeys commands (+6)',
        value: 6
      },
      {
        label: 'Localizes pain (+5)',
        value: 5
      },
      {
        label: 'Withdrawal from pain (+4)',
        value: 4
      },
      {
        label: 'Flexion to pain (+3)',
        value: 3
      },
      {
        label: 'Extension to pain (+2)',
        value: 2
      },
      {
        label: 'No motor response (+1)',
        value: 1
      },
      {
        label: 'Not testable (NT)',
        value: 0
      }
    ]
  }
]

export const ichData = [
  {
    question: "Largest diameter of the hemorrhage on CT?",
    measurement: "cm"
  },
  {
    question: "Largest diameter 90° to A on the same CT slice?",
    measurement: "cm"
  },
  {
    question: "Number of CT slices on which hemorrhage is visible?",
    measurement: ""
  },
  {
    question: "CT Slice Thickness",
    measurement: "mm"
  }
]