import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from "styled-components";

const ICHResult = ({thickness, inputList}) => {
  console.log('thickness', thickness);
  const renderTotalScore = () => {
    const value = inputList[0] * inputList[1] * inputList[2];
    console.log('inputList',inputList[0]);
    console.log('value',value);
    const result = value / thickness;
    return (
      <h2 className="text-center">
        {result} <span>mL (cm³)</span>
      </h2>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          {renderTotalScore()}
        </Col>
      </Row>
    </Container>
  )
}

export default ICHResult
